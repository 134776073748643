<template lang="pug">
.q-px-lg.q-py-md

  .q-mb-lg
    .text-grey-8 WebRTC를 이용하여 User와 Machine이 video & text chatting 을 하는 페이지 입니다.

  q-tabs(v-model="tab" align="justify")
    q-tab(name="video" label="Video")
    q-tab(name="chat" label="Chat")
    q-tab(name="debug" label="Debug")
  q-separator

  q-tab-panels(v-model="tab")

    //- VIDEO
    q-tab-panel(name="video")
      .q-gutter-y-md
      
        q-input(
          label="Session ID (click me to start)"
          stack-label filled readonly
          @click="openChooseOptionDialog"
          v-model="sessionId")
      
        video(ref="c-video" autoplay controls muted style="width:100%;" :srcObject="videoStream")
        //- audio(v-if="audioStream" ref="c-audio" autoplay controls style="width:100%;" :srcObject="audioStream")
              
    //- CHAT
    q-tab-panel(name="chat")
      .q-gutter-y-md
      
        q-input(
          label="Session ID (click me to start)"
          stack-label filled readonly
          @click="openChooseOptionDialog"
          v-model="sessionId")
      
        .bg-grey-3
          .scroll(style="height: 300px; white-space: break-spaces;" ref="chat-box")
            | {{chat}}
              
        q-input(
          v-model="message"
          @keyup.enter="onKeyupEnter"
          placeholder="enter a message and hit 'enter' to send"
          filled)

    //- DEBUG
    q-tab-panel(name="debug")
      .q-gutter-y-md

        q-markup-table(separator="cell" flat bordered)
          thead
            tr
              th Type
              th Address
              th Related Address
          tbody
            tr(v-for="(v, i) in offerCandidates")
              td(v-if="i === 0" :rowspan="offerCandidates.length") Offer
              td {{`${v.protocol}://${v.ip}:${v.port}`}}
              td {{v.relatedAddress ? `${v.protocol}://${v.relatedAddress}:${v.relatedPort}` : '-'}}
            tr(v-for="(v, i) in answerCandidates")
              td(v-if="i === 0" :rowspan="answerCandidates.length") Answer
              td {{v.protocol}}://{{v.ip}}:{{v.port}}
              td {{v.relatedAddress ? `${v.protocol}://${v.relatedAddress}:${v.relatedPort}` : '-'}}
      
        .bg-grey-3
          .scroll(style="height: 200px; white-space: break-spaces;")
            | {{offer}}
      
        .bg-grey-3
          .scroll(style="height: 200px; white-space: break-spaces;")
            | {{answer}}

</template>
<script>
import Peer from "simple-peer"
export default {
  computed: {
  },
  data: function() {
    return {
      initiator: true,
      sessionId: '',
      p: null,
      message: '',
      chat: '',
      isP2PConnected: false,
      videoStream: null,
      audioStream: null,
      // 
      iceServer: null,
      signalServer: null,
      wsHost: null,
      sendingSignal$i: null,
      remoteSignalUpdated: false,
      // 
      tab: 'video',
      offer: '',
      offerCandidates: [],
      answer: '',
      answerCandidates: [],
    }
  },
  watch: {
    chat: function () {
      const ref = this.$refs['chat-box']
      if (!ref) return
      setTimeout(() => ref.scrollTop = ref.scrollHeight, 100)
    },
    offer: function () {
      if (!this.offer) this.offerCandidates = []
      this.offer.split("\n").forEach(v => {
        if (!v.includes("a=candidate:")) return
        this.offerCandidates.push(this.$tool.parseICECandidate(v))
      })
    },
    answer: function () {
      if (!this.answer) this.answerCandidates = []
      this.answer.split("\n").forEach(v => {
        if (!v.includes("a=candidate:")) return
        this.answerCandidates.push(this.$tool.parseICECandidate(v))
      })
    },
  },
  mounted: function () {
    const video = this.$refs['c-video']
    if (!video) return
    video.addEventListener('loadedmetadata', function() {
      console.log(`video videoWidth: ${this.videoWidth}px,  videoHeight: ${this.videoHeight}px`)
    })
    video.addEventListener('resize', function() {
      console.log(`video size changed videoWidth: ${this.videoWidth}px,  videoHeight: ${this.videoHeight}px`)
    })
  },
  beforeUnmount: function() {
    this.destroyICE()
    this.disconnectWSHost()
    this.stopSendingSignal()
  },
  methods:{
    init: async function (sessionId) {
      if (!sessionId) {
        this.initiator = true
      } else {
        this.initiator = false
      }
      await this.initICESignal(sessionId)
      this.initICE()
      this.connectWSHost()
    },
    printSignal: function (signal) {
      console.log(signal)
      console.log(signal.sdp)
    },
    //
    initICESignal: async function (sessionId) {
      if (sessionId) {
        this.sessionId = sessionId
      } else {
        this.sessionId = this.$tool.generateSessionID()
      }
      const res = await this.$ham.getICESignal({sessionId: this.sessionId, initiator: this.initiator})
      this.iceServer = res.data.iceServer
      this.signalServer = res.data.signalServer
    },
    // 
    initICE: function () {
      if (!this.iceServer) return
      // INIT peer
      this.p = new Peer({
        initiator: this.initiator,
        trickle: false,
        config: {iceServers: [this.iceServer]},
      })

      // Signaling
      this.p.on('signal', signal => {
        this.appendToChat(`[GOT-SIGNAL] ${new Date().toISOString()}`)
        this.startSendingSignal(signal)
        this.printSignal(signal)
      })

      // Event handlers
      this.p.on('error', err => this.appendToChat(`[ERROR] ${err.message}`))
      this.p.on('close', () => this.appendToChat(`[CLOSE] ${new Date().toISOString()}`))
      this.p.on('connect', () => {
        this.appendToChat(`[CONNECTED] ${new Date().toISOString()}`)
        this.isP2PConnected = true
        this.stopSendingSignal()
      })
      this.p.on('data', data => this.appendToChat(`machine: ${data}`))
      this.p.addTransceiver('video', {'direction': 'recvonly'})
      this.p.addTransceiver('audio', {'direction': 'recvonly'})
      // this.p.on('stream', (stream) => {
      //   console.log("stream event new", stream)
      //   const video = this.$refs['c-video']
      //   if (!video) return
      //   if ('srcObject' in video) {
      //     console.log("has srcObject")
      //     this.videoStream = stream
      //     // video.srcObject = stream
      //   }
      // })
      this.p.on('track', (track, stream) => {
        this.appendToChat(`[GOT-${track.kind}-TRACK] ${new Date().toISOString()}`)
        switch (track.kind) {
        case "video":
          this.videoStream = stream
          break
        case "audio":
          // this.videoStream = stream
          // this.audioStream = stream
          console.log("audio stream", stream)
          break
        default:
          console.log("uknown track of", track)
        }
      })
    },
    destroyICE: function () {
      if (!(this.p instanceof Object)) return
      this.p.destroy()
      this.p = null
    },
    // 
    connectWSHost: function () {
      if (!this.signalServer) return
      const url = `${this.signalServer.path}/v2/${this.signalServer.token}`
      this.wsHost = new WebSocket(url)
      // 
      this.wsHost.onopen = function (e) {
        console.log(e)
      }
      this.wsHost.onmessage = function (e) {
        // PARSE
        const packet = JSON.parse(e.data)
        const type = packet.t
        const payload = packet.p
        const meta = packet.m
        const event = meta.o
        const from = meta.f
        // const to = meta.t

        // CASE - string
        if (!(payload instanceof Object)) {
          this.appendToChat(`ws_recv: ${event}: ${JSON.stringify(payload)}: from: ${from}`)
          return
        }

        // CASE - object
        // CHECK routines
        if (type !== "u") return // non-user message
        if (event && event !== "message") return // non-message
        if (from === this.signalServer.from) return  // message from me
        this.appendToChat(`ws_recv: ${event}: ${JSON.stringify(payload).length} bytes: from: ${from}`)
        this.updateRemoteSignal(payload)
      }.bind(this)
    },
    disconnectWSHost: function () {
      if (!(this.wsHost instanceof Object)) return
      this.appendToChat(`[DISCONNECT-WSHOST] ${new Date().toISOString()}`)
      this.wsHost.close()
      this.wsHost = null
    },
    //
    startSendingSignal: function (signal) {
      this.appendToChat(`[START-SIGNALLING] ${new Date().toISOString()}`)
      this.stopSendingSignal()
      this.sendMessageWSHost(signal)
      this.sendingSignal$i = setInterval(() => {
        this.sendMessageWSHost(signal)
      }, 1000 * 3)
    },
    stopSendingSignal: function () {
      if (!this.sendingSignal$i) return
      this.appendToChat(`[STOP-SIGNALLING] ${new Date().toISOString()}`)
      clearInterval(this.sendingSignal$i)
      this.sendingSignal$i = null
      this.disconnectWSHost()
    },
    updateRemoteSignal: function (signal) {
      this.printSignal(signal)

      // CHECK 1
      if (this.remoteSignalUpdated) return
      if (!this.p) return

      // CHECK 2
      switch (signal.type) {
      case "answer":
        if (!this.initiator) return
        break
      case "offer":
        if (this.initiator) return
        break
      default:
        return
      }

      // SET - remote signal(sdp)
      this.p.signal(signal)
      this.remoteSignalUpdated = true
    },
    // 
    sendMessageWSHost: function (message) {
      if (!this.wsHost) return
      const msg = {
        t: "u",
        m: {
          o: "message",
          f: this.signalServer.from,
          t: this.signalServer.to,
        },
        p: message,
      }
      this.appendToChat(`ws_send: ${msg.m.o}: ${JSON.stringify(msg.p).length} bytes: to: ${msg.m.t}`)
      this.wsHost.send(JSON.stringify(msg))
    },
    appendToChat: function (message) {
      this.chat = this.chat ? `${this.chat}\n${message}` : message
    },
    // 
    openChooseOptionDialog: function () {
      this.$q.dialog({
        title: 'Choose an option:',
        options: {
          type: 'radio',
          model: 'join',
          items: [
            { label: 'JOIN an exising session', value: 'join' },
            { label: 'CREATE a new Session', value: 'new' },
          ]
        },
        cancel: true,
      }).onOk(data => {
        switch (data) {
          case 'join':
            this.openJoinSessionDialog()
            break
          case 'new':
            this.init("")
            break
        }
      })
    },
    openJoinSessionDialog: function () {
      this.$q.dialog({
        title: 'Join Session',
        message: 'Enter Session ID to join.',
        prompt: {
          model: '',
          isValid: val => val.length < 8,
        },
        cancel: true,
      }).onOk(sessionId => {
        this.init(sessionId)
      })
    },
    // 
    onKeyupEnter: function () {
      // CHECK
      if (!this.isP2PConnected) return
      if (!this.p) return

      // ACTION
      const message = `user: ${this.message}`
      this.p.send(this.message || " ")
      this.message = ""
      this.appendToChat(message)
    },
  },
}
</script>